@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth !important;
  /* height: 110vh !important; */

}
body {
  margin: 0;
  font-family: Montserrat !important;
  scroll-behavior: smooth !important;
  height: auto;

} 

code {
  font-family: Montserrat !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 46%);
  z-index: 999;
}

.select-custom::-ms-expand {
  margin-right: 12px !important;
}

.herobg {
  background-image: url(../src/assets/image/herobg2.png);
  background-size: 60% 35%;
  background-repeat: no-repeat;
  background-position: top right;
}
.herobg2 {
  background-image: url(../src/assets/image/herobg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top right;
}
.bgposterimg{
  background-image: url(../src/assets/image/campaign.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}
.herobguk {
  background-image: url(../src/assets/image/heroukbg.png);
  background-size: 100% 89%;
  background-repeat: no-repeat;
  background-position: bottom;
}

.bgmission {
  background-image: url(../src/assets/image/bgmission.jpg);
  background-size: cover;
  background-position: center;
}


.herobg2 {
  background-image: url(../src/assets/image/herobg2.png);
  background-repeat: no-repeat;
  background-size: 70% 100%;
  background-position: right;
}
.valuebggr{
  background-image: url(./assets/image/bgvalvebot.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.valuecardbg{
border-radius: 13.643px;
border: 1.49px solid #1E5BB3;
background: linear-gradient(98deg, #E9F2FF 15.93%, #A6BBD8 116.22%);
}

.bgmissiongr {
  background: linear-gradient(286deg, rgba(255, 255, 255, 0.93) -0.49%, rgba(255, 255, 255, 0.80) 104.29%);
}
.bgforminput{
background: rgba(255, 255, 255, 0.29);
backdrop-filter: blur(11.350000381469727px);
}
.towaybg{
  border-radius: 16px;
border: 1px solid #A5CAFF;
background: linear-gradient(239deg, #D2E5FF 0.89%, #FFF4CC 100%);
}
.bgformimg{
  background-image: url(../src/assets/image/formbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 220px;

  
}
.msgimg{
  border-radius: 12.852px;
box-shadow: 4.696px 5.87px 11.388px 0px rgba(0, 0, 0, 0.50);
}

.bgbtn {
  border-radius: 34px;
  background: linear-gradient(90deg, #FE8516 0%, #FECE16 100%);
  transition: ease 0.5s !important;
  border: 1px solid transparent;
}
.bundelbggrone {
  border-radius: 16px;
  background: linear-gradient(90deg, #FE8516 0%, #FECE16 100%);
  transition: ease 0.5s !important;
}
.bgbtngrbd {
  border-radius: 34px;
  background: linear-gradient(90deg, #FE8516 0%, #FECE16 100%);
}
.bgukbundel {
  border-radius: 16px;
  border: 1px solid rgba(30, 91, 179, 0.21);
  background: linear-gradient(270deg, #1E5BB3 -9.67%, #0D274D 128.26%);
}
.inputbunduk::placeholder {
  color: #fff; 
}
.bgbtn:hover {
  background: linear-gradient(90deg, #fafafa 0%, #fff 100%) !important;
  border: 1px solid #FE8516;
}
.faqstrong a {
  text-decoration: underline !important;
}

.bgbtn2 {
  border-radius: 10px;
  background: linear-gradient(90deg, #FE8516 0%, #FECE16 100%);
}
.bgbtnafter{
  background: linear-gradient(90deg, #ffb06793 0%, #fcce18 100%);
}

.counter {
  background: linear-gradient(90deg, #fa8211 0%, #f8c90f 100%);
}
.videoclass video{
  height: 420px !important;
}
.videoclassmob video{
  height: 200px !important;
}

.backicon{
  transform: rotate(-181deg) !important;
}

.philbg {
  background: linear-gradient(0deg, #f5d34f 0%, #f7dd75 100%);
}

.Purposeful {
  background-image: url(../src/assets/image/Frame\ 1410121205.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Purposefulmob {
  background-image: url(../src/assets/image/mobpter.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.herobgrecive{
  border-radius: 34px;
background: linear-gradient(90deg, #1E5BB3 0%, #24315E 100%);
}
.petermsg video{
height: 400px !important;
}
.popupsho{
  border-radius: 4px;
border: 0.5px solid #DDDCE5;
background: var(--White, #FFF);
box-shadow: 0px 22.827px 22.827px 0px rgba(32, 39, 57, 0.05);
}
.bundelbggr{
  border-radius: 34px;
background: linear-gradient(-90deg, rgba(254, 133, 22, 0.90) 0.01%, #FECE16 24.5%);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.errorbg{
  background-color: rgb(255 15 41 / 16%);
}

.teambg {
  border-radius: 5px;
  background: rgba(71, 50, 186, 0.03);
}
.bgmassive{
  background-image:url(./assets/image/massive.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.massivebgcard{
  border-radius: 40px;
background: rgba(31, 41, 76, 0.88);
box-shadow: 8px 8px 38.5px 0px rgba(0, 0, 0, 0.20);
backdrop-filter: blur(9.5px);
}
.spobgactive{
  border-radius: 20.975px;
border: 0.499px solid #1F294C !important;
background: rgba(233, 242, 255, 0.54);
box-shadow: 11.986px 12.985px 13.284px 0px rgba(0, 0, 0, 0.15) inset;
}
.bgspon1{
  background-image: url(./assets/image/spnimg\ \(6\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon2{
  background-image: url(./assets/image/spnimg\ \(2\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon3{
  background-image: url(./assets/image/spnimg\ \(3\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon4{
  background-image: url(./assets/image/spnimg\ \(4\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon5{
  background-image: url(./assets/image/spnimg\ \(5\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon6{
  background-image: url(./assets/image/spnimg\ \(7\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.bgspon7{
  background-image: url(./assets/image/spnimg\ \(1\).png);
  background-repeat: no-repeat;
  background-size: contain;
}
.butn1spbg{
  border-radius: 29.896px;
  background: linear-gradient(90deg, #241D40 -101.3%, #EBEBEC -61.91%, #6F7072 -14.63%, #60607B 1.13%, #11111F 22.14%, #3D3D66 39.18%, #333358 48.4%, #404064 62.82%, #11111F 77.29%, #5A5A71 111.43%, #ABACAE 116.69%, #9C9D9F 121.94%, #949597 127.19%, #919294 135.07%, #8A8B8D 140.32%, #767779 153.46%, #6F7072 161.34%);
  box-shadow: 7.034px 17.586px 35.172px 0px rgba(0, 0, 0, 0.15);
}
.butn2spbg{
  border-radius: 29.896px;
  background: #E9FBFF;
  box-shadow: 0px 17.586px 35.172px 0px rgba(0, 0, 0, 0.15);
}
.butn3spbg{
  border-radius: 29.896px;
  background: linear-gradient(90deg, #F1C540 -36.98%, #EFE1AB 8.19%, #FBD06D 21.63%, #FFEBBC 41.58%, #F5BC36 57.06%, #EFE0AB 77.82%, #FFC33A 116.49%, #F0AF2F 118.39%, #DF9A22 122.18%, #D38B1A 124.07%, #CC8315 127.86%, #CA8013 133.55%, #DA9A1A 141.12%, #E6AE20 148.7%, #EAB522 152.49%);
  box-shadow: 0px 17.586px 35.172px 0px rgba(0, 0, 0, 0.15);
}
.butn4spbg{
  border-radius: 29.896px;
background: linear-gradient(90deg, #CECFD0 -101.3%, #EBEBEC -61.91%, #6F7072 -14.63%, #EBEBEC 9.97%, #A6A8A9 30.03%, #E0E0E0 52.96%, #F0F0F0 72.27%, #B6B6B8 90.11%, #ABACAE 116.69%, #9C9D9F 121.94%, #949597 127.19%, #919294 135.07%, #8A8B8D 140.32%, #767779 153.46%, #6F7072 161.34%);
box-shadow: 0px 17.586px 35.172px 0px rgba(0, 0, 0, 0.15);
}
.butn5spbg{
  border-radius: 29.896px;
background: linear-gradient(271deg, #60230B 0.18%, #591F0A 1.17%, #4C1808 3.16%, #451307 7.14%, #431207 14.11%, #441307 15.11%, #6F2B0E 29.05%, #803510 36.01%, #873B15 39%, #9B4C23 43.98%, #BC6739 48.96%, #D77E4C 52.94%, #6B1B0C 78.82%, #431207 99.73%);
}
.butn6spbg{
  border-radius: 29.896px;
  background: linear-gradient(90deg, #5F1B00 -4.36%, #974C2C 7.6%, #FFA87F 28.26%, #FFC7AE 43.21%, #FFDED2 52.26%, #FFE7DF 52.28%, #D17B5A 71.74%, #3F0600 104.36%);
}
.butn7spbg{
  border-radius: 29.896px;
background: linear-gradient(90deg, #241D40 -101.3%, #EBEBEC -61.91%, #6F7072 -14.63%, #8693A9 1.13%, #7B8EAD 22.14%, #A9B9C8 39.18%, #BDCDE3 48.4%, #8A9EBD 62.82%, #6D7B97 77.29%, #6E7F9F 111.43%, #ABACAE 116.69%, #9C9D9F 121.94%, #949597 127.19%, #919294 135.07%, #8A8B8D 140.32%, #767779 153.46%, #6F7072 161.34%);
box-shadow: 7.034px 17.586px 35.172px 0px rgba(0, 0, 0, 0.15);
}
.card1gr{
  border-radius: 20.975px;
background: rgba(233, 242, 255, 0.54);
box-shadow: 11.986px 12.985px 13.284px 0px rgba(0, 0, 0, 0.15) inset;
}
.cardinnergr{
  border-radius: 8px;
border: 1px solid #A5CAFF;
background: linear-gradient(239deg, #D2E5FF 0.89%, #FFF4CC 100%);
}
.hedgr{
  background: var(--primary-Gradient, linear-gradient(87deg, #0C1F3F -13.27%, #1E5BB3 58.98%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.meidabg{
  background-image: url(../src/assets/image/ourmeimg.png);
  background-repeat: no-repeat;
  background-size: 70% 100%;
  background-position: center;
}
.bgourimg{
  background-image: url(../src/assets/image/bgoueimg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.spswiper.swiper-horizontal{
  overflow: visible !important;
}
.clip{
  clip-path: polygon(0 51%, 52% 0, 100% 51%, 100% 100%, 0 100%);
  fill: rgba(233, 242, 255, 0.54);
box-shadow: 11.986px 12.985px 13.284px 0px rgba(0, 0, 0, 0.15) inset;
}
.spswiper.swiper-css-mode > .swiper-wrapper{
  overflow-y: visible !important;
  overflow-x: hidden !important;
  overflow: auto;

}

.fewbg {
  background: rgba(30, 91, 179, 0.09);
}

.whyteam {
  border-radius: 8px;
  background: var(--White, #FFF);
  box-shadow: 2px 4px 45.9px 0px rgba(0, 0, 0, 0.08);
}

.answer strong {
  color: #1E5BB3 !important;
}

.tabiconbg {
  background-color: #FFF;
  filter: drop-shadow(0px 4px 45.5px rgba(0, 0, 0, 0.10));
}

.tabtext {
  color: rgba(30, 91, 179, 0.50)
}

.tabbg {
  border-radius: 12px;
  background: rgba(30, 91, 179, 0.08);
}

.formborder {
  border-bottom: 1px solid #1E5BB34C;
}

.benefitsbg {
  border-radius: 15px;
  background: rgba(30, 91, 179, 0.09);
}

.planbg {
  border-radius: 16px;
  background: var(--Primary, #1E5BB3);
}

.formInput input::placeholder {
  color: #1E5BB34C;
}

.bgleftimg {
  background-image: url(../src/assets/image/leftimg.png);
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 376px;
  height: 100%;
}

.bgrightimg {
  background-image: url(../src/assets/image/rightimg.png);
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 376px;
  height: 100%;
}

@media (max-width: 600px) {
  .bglogomob{
    background-image: url(../src/assets/image/moblogo.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 230px;
    background-position: bottom;
    height: 100%;
}

  .PaymentElement {
    /* Container styles */
    /* height: 70px; */
    border: 1px solid #ccc;
    border-radius: 10px !important;
    padding: 12px 13px !important;
    font-size: 16px !important;
  }

  .bgleftimg {
    background-image: url(../src/assets/image/leftimg.png);
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 248px;
    height: 100%;
  }

  .bgrightimg {
    background-image: url(../src/assets/image/rightimg.png);
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 248px;
    height: 100%;
  }
}

.orangetext {
  background: var(--secondary-Gradient, linear-gradient(90deg, #FECE16 0%, #FE8516 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donatebg {
  background: var(--primary-Gradient, linear-gradient(87deg, #0C1F3F -13.27%, #1E5BB3 58.98%));
  box-shadow: 9px 0px 40px 0px rgba(0, 0, 0, 0.28)
}

.textgcolor {
  background: linear-gradient(87deg, #0C1F3F 43.63%, #1E5BB3 83.63%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  leading-trim: both;
  text-edge: cap;
}

.cookiebg {
  background: linear-gradient(100deg, #FFF9E1 31.75%, #e7f0fd 100%);
}
.navbar-scrolled {
  background-color: red;
  transition: background-color 1s ease; /* Smooth transition effect */
}

.mediacir {
  stroke-width: 1.069px;
  stroke: rgba(30, 91, 179, 0.44);
}

.PhoneInputInput {
  outline: none !important;
}

.news::-webkit-scrollbar {
  width: 12px;
  max-height: 50%;
  height: 50%;
}

.news::-webkit-scrollbar-track {
  border-radius: 41px;
  background: rgba(30, 91, 179, 0.32);
}

.news::-webkit-scrollbar-button {
  height: 83px !important;
}

.news::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--Primary, #1E5BB3);
}

.bgcardimg {
  background-image: url(../src/assets/image/phimg\ \(2\).png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 150px;
}

.bgcardimg2 {
  background-image: url(../src/assets/image/phimg\ \(3\).png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 150px;
}

.bgcardimg3 {
  background-image: url(../src/assets/image/phimg\ \(1\).png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 150px;
}

.phbackground {
  border-radius: 12px;
  background: var(--White, #FFF);
  box-shadow: 0px 4px 31.7px 0px rgba(0, 0, 0, 0.10);
}

.shadowrole {
  -webkit-box-shadow: inset 13px 8px 23px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 13px 8px 23px -17px rgba(0, 0, 0, 0.75);
  box-shadow: inset 13px 8px 23px -17px rgba(0, 0, 0, 0.75);
}

.corpcard {
  border-radius: 5px 10px 10px 5px;
  background: rgba(71, 50, 186, 0.03);
}

.contantgr {
  background: linear-gradient(180deg, rgba(90, 126, 177, 0.00) 0%, #5A7EB1 56.98%);
}

.contantgr2 {
  background: linear-gradient(180deg, rgba(30, 91, 179, 0.00) 0%, #1E5BB3 56.98%);
}

.contimg1 {
  background-image: url(../src//assets/image/contimg\ \(1\).png);
  background-size: 80% 88%;
  background-repeat: no-repeat;
  max-height: 288px;
  background-position: center;
}

.PaymentElement {
  /* Container styles */
  /* height: 70px; */
  border: 1px solid #ccc;
  border-radius: 13px;
  padding: 28px 13px;
  font-size: 16px !important;
}

.PaymentElement input[type="text"],
.PaymentElement select {
  /* Input and select styles */
  flex: 1 !important;
  /* Take up remaining space */
  height: 70px !important;
  padding: 5px !important;
  margin-right: 10px !important;
  /* Add some spacing between elements */
  border: 1px solid #ccc !important;
  border-radius: 13px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
}

.__PrivateStripeElement iframe {
  margin: 0 auto;
}

.PaymentElement select {
  font-size: 16px !important;
}

.ElementsApp {
  font-size: 16px !important;
}

.CardField-cvc input,
.CardField-expiry input,
.CardField-number .CardField-number-fakeNumber,
.CardField-number input {
  transition: opacity .3s cubic-bezier(.25, .46, .45, .94);
  font-size: 16px !important;
}

.ElementsApp input {
  font-size: 1.1rem !important;
}

.custom-card-field .CardField--ltr.CardField--hideExpiry.CardField--hidePostal.CardField--hideCVC {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
}

.contimg2 {
  background-image: url(../src//assets/image/contimg\ \(2\).png);
  background-size: 80% 88%;
  background-repeat: no-repeat;
  max-height: 288px;
  background-position: center;
}

.contimg3 {
  background-image: url(../src//assets/image/contimg\ \(3\).png);
  background-size: 80% 88%;
  background-repeat: no-repeat;
  max-height: 288px;
  background-position: center;
}

.contimg4 {
  background-image: url(../src//assets/image/contimg\ \(4\).png);
  background-size: 80% 88%;
  background-repeat: no-repeat;
  max-height: 288px;
  background-position: center;
}

.donatebadge {
  border-radius: 4px;
  background: #111;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.swiper-pagination-bullet-active {
  height: 15px !important;
  width: 15px !important;
  background-color: #1E5BB3 !important;
}

.swiper-pagination-bullet {
  height: 15px;
  width: 15px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.paybgimg {
  background-image: url(../src/assets/image/paybg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.paybgarmyimg {
  background-image: url(../src/assets/image/paybgarmy.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.herothankyou{
  background-image: url(./assets/image/thankyoudon\ \(2\).png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 375px;
}
.thankyou::placeholder{
  color: #1E5BB3 !important;
}
.thankbgcer{
  background-image: url(./assets/image/thankyoudon\ \(1\).png);
  background-size: 68% 100%;
  display: flex;
  align-items: flex-end;
  height: auto;
  background-repeat: no-repeat;
}
.Updatedthankbg{
  background-image: url(./assets/image/thankyoudon\ \(5\).png);
  background-size: 70% 100%;
  height: 300px;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.Updatedthankbg2{
  background-image: url(./assets/image/thankyoudon\ \(4\).png);
  background-size: 70% 100%;
  height: 600px;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  background-position-x: right;
  z-index: -1;
  bottom: 0%;
}
.joinbg{
  background-image: url(./assets/image/join.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 280px;

}
.amountinput::placeholder{
  color: #fff !important;
}
.social .swiper-wrapper{
  padding-top: 2rem !important;
}
.swiper-button-prev{
  background-image: url(./assets/image/leftic.png) !important;
  z-index: 99 !important;
  height: 20px !important;
  background-repeat: no-repeat !important;
  width: 20px !important;
  background-size: 100% 100%;
  left: -2px !important;

  
}
.swiper-button-next{
  background-image: url(./assets/image/reightic.png) !important;
  z-index: 99 !important;
  height: 20px !important;
  width: 20px !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
  right: -2px !important;

}
.swiper-button-prev:after{
  display: none !important;
 
}
.herovideo{
  border-radius: 20px !important;
}
.swiper-button-next::after{
  display: none !important; 
}
.Celebrity .swiper-button-prev{
  background-image: url(./assets/image/leftic.png) !important;
  z-index: 99 !important;
  height: 20px !important;
  background-repeat: no-repeat !important;
  width: 20px !important;
  background-size: 100% 100%;
  display: none !important;
}
.Celebrity .swiper-button-next{
  background-image: url(./assets/image/reightic.png) !important;
  z-index: 99 !important;
  height: 20px !important;
  width: 20px !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
  display: none !important;

}
.react-tel-input .form-control {
  position: relative;
  font-size: 18px !important;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: none !important;
  border-radius: 5px;
  line-height: 25px;
  height: 40px !important;
  max-width: 300px !important;
  width: 100% !important;
  outline: none;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 0px !important;
  border-radius: 3px 0 0 3px;
}
.show-animation {
  transition-delay: 2s;
}
.Amountsec ::-webkit-scrollbar {
 display: none !important;
}
.benefits ::-webkit-scrollbar {
  display: none !important;
 }
 .cebimg{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
 }

 .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: 1 !important;
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
  .massivebgcard2{
    background: rgba(31, 41, 76, 0.88);
    }
  .herobguk {
    background-image: url(../src/assets/image/heroukbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .bgmission {
    background-image: url(../src/assets/image/bgmission.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .herobg2 {
    background-image: url(../src/assets/image/herobg2.png);
    background-size: 40% 50%;
    background-repeat: no-repeat;
    background-position: top right;
  }
  .valuebg{
    background-image: url(./assets/image/ourvalue.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

  }
  .joinbg{
    background-image: url(./assets/image/join.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 180px;
  
  }
  .react-tel-input .form-control {
    position: relative;
    font-size: 14px !important;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: none !important;
    border-radius: 5px;
    line-height: 25px;
    height: 35px !important;
    width: 280px !important;
    outline: none;
  }
  .thankyou::placeholder{
    color: #1E5BB3 !important;
    font-size: 14px;
  }
  .Influencer .swiper-button-prev{
    background-image: url(./assets/image/leftic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    background-repeat: no-repeat !important;
    width: 20px !important;
    background-size: 100% 100%;
    display: none !important;
  }
  .Influencer .swiper-button-next{
    background-image: url(./assets/image/reightic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    width: 20px !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    display: none !important;
  
  } 
  .Content .swiper-button-prev{
    background-image: url(./assets/image/leftic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    background-repeat: no-repeat !important;
    width: 20px !important;
    background-size: 100% 100%;
    display: none !important;
  }
  .Content .swiper-button-next{
    background-image: url(./assets/image/reightic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    width: 20px !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    display: none !important;
  
  }
  .Celebrity .swiper-button-prev{
    background-image: url(./assets/image/leftic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    background-repeat: no-repeat !important;
    width: 20px !important;
    background-size: 100% 100%;
    display: block !important;
  }
  .Celebrity .swiper-button-next{
    background-image: url(./assets/image/reightic.png) !important;
    z-index: 99 !important;
    height: 20px !important;
    width: 20px !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    display: block !important;
  
  }
}



.paybgimgmob {
  background-image: url(../src/assets/image/paybg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  min-height: 600px;
}
.paybgarmymob {
  background-image: url(../src/assets/image/paybgarmy.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  min-height: 600px;
}


.paygrbg {
  background: linear-gradient(181deg, #DFEBFC 18.01%, rgba(223, 235, 252, 0.00) 99.19%);
}

.bundelvalue {
  border-radius: 34px;
  background: linear-gradient(90deg, rgba(254, 206, 22, 0.95) 73.5%, rgba(254, 133, 22, 0.85) 98.5%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}

.forminputbg {
  background: rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(25px);
  padding: 20px;
}
.faqstrong strong{
  color: #111 !important;
}

